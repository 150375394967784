<template>
  <div>
    <el-table :data="tableData" border>
      <el-table-column type="index" width="50" label="序号" />
      <el-table-column label="商户名称">
        <template slot-scope="{ row }">{{ row.short_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="{ row }">{{ row.modified || row.created || '-' }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <template v-if="scope.row.is_done === '0'">
            <router-link :to="{ name: 'DataReview', query: { merchant_no: scope.row.no } }">继续填写</router-link>
            <span @click="scheduleDrop(scope.row.no, scope.$index)" class="red pointer">&nbsp;删除</span>
          </template>
          <span v-else-if="scope.row.is_done === '1'">进件中</span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" @init="init" ref="pagination" />
  </div>
</template>

<script>
import { scheduleDrafts, scheduleDrop } from '@/api/api'
import Pagination from '@/components/pagination'
import { MessageBox } from 'element-ui'

export default {
  name: 'Drafts', //草稿箱
  components: { Pagination },
  data() {
    return {
      tableData: [],
      total: 10
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const pagination = this.$refs.pagination
      const params = {
        page: pagination.page,
        limit: pagination.limit
      }
      scheduleDrafts(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.total = res.data.count
        }
      })
    },
    // 删除草稿
    scheduleDrop(draft_merchant_no, index) {
      MessageBox.confirm('此操作将永久删除该草稿, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          scheduleDrop({ draft_merchant_no }).then((res) => {
            if (res.code === 200) {
              this.tableData.splice(index, 1)
              this.total--
            }
          })
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

